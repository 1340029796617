import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { ArrowRight, Mail } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image";

import Kickboxing from '../../static/assets/Kickboxing.png'
import Mountain from '../../static/assets/Mountain.png'
import Broadway from '../../static/assets/Camera.png'
import Dog from '../../static/assets/Dog.png'
import Barn from '../../static/assets/Barn.png'
import Veggies from '../../static/assets/Veggies.png'
import House from '../../static/assets/House.png'
import Heart from '../../static/assets/Heart.png'
import Paw from '../../static/assets/Paw.png'
import Dumbbell from '../../static/assets/Dumbbell.png'

const About = () => {
  return <>
    <Helmet>
      <title>Anna Roto Cataldo - Enriched</title>
      <meta name="description" content="Anna Roto Cataldo - Enriched" />
    </Helmet>
    <div className="resume-page">
    <div className="resume-column">
    <div className="top-section">
    <h1>Anna Roto Cataldo</h1>
    <h6 className="top">MS, MPH, RD</h6>
    <div className="about-photo">
      <StaticImage
              src='../../static/assets/Anna.png'
              alt="Anna Roto Cataldo"
            />
    </div>
    </div>
    <h4>Contact</h4>
    <div className="email">
      <Mail /><a href="mailto:anna@enriched.health">anna@enriched.health</a>
    </div>
    <p>Anna Roto Cataldo, MS, MPH, RD, has studied and worked in the nutrition and fitness fields for 14+ years. 
            Her fascination with human metabolism and performance led her down a rigorous academic and clinical training 
            path in biochemistry, exercise physiology and nutrition science. Anna’s goal is to share her knowledge and 
            experiences in a way that helps others build confidence in and a sense of control over their health and well-being.
    </p>
    <h4>Education</h4>
    <div className="timeline-item">
      <h5>2017<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>P<span style={{textTransform:'lowercase'}}>h</span>D Candidate in Rehabilitation Science</h4>
      <h6>Boston University</h6>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2016<br />-<br />2017</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Dietetic Clinical Internship</h4>
      <h6>Brigham and Women’s Hospital</h6>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2012<br />-<br />2015</h5>
      <h3>
        •
        <div className="line second-last" />
      </h3>
      <div className="timeline-info">
      <h4>MS Biochemical and Molecular Nutrition</h4>
      <h6>Tufts University Friedman School of Nutrition Science and Policy</h6>
      <h4>MPH Public Health Nutrition and Nutrition Communication</h4>
      <h6>Tufts University School of Medicine</h6>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2007<br />-<br />2011</h5>
      <h3>
        •
        <div className="line last" />
      </h3>
      <div className="timeline-info">
      <h4>BS Nutrition Science</h4>
      <h4>BS Exercise Physiology</h4>
      <h6>University of Connecticut</h6>
      </div>
    </div>
    </div>
    <div className="resume-column">
    <div className="top-section">
    <h4 className="no-mt">Interests</h4>
    <div className="timeline-item interests">
      <img
                src={Dumbbell}
                alt="HIIT"
              /> 
      <h6>HIIT</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Veggies}
                alt="Gardening"
              /> 
      <h6>Gardening</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={House}
                alt="Interior Design"
              /> 
      <h6>Interior Design</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Paw}
                alt="Frisbee with Lady (my dog!)"
              /> 
      <h6>Frisbee with Lady (my dog!)</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Heart}
                alt="Running Around the Yard with my Daughter & Husband"
              /> 
      <h6>Running Around the Yard with my Daughter & Husband</h6>
    </div>
    </div>
    <h4>Experience</h4>
    <div className="timeline-item">
      <h5>2018<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Nutrition eConsultant Specialist</h4>
      <h6>RubiconMD Inc.</h6>
      <p>Providing timely treatment recommendations and advice regarding the nutritional care of patients for primary care physicians across the nation</p>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2017<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>PhD Candidate</h4>
      <h6>Neuromotor Recovery Laboratory, Boston University</h6>
      <h6>Harvard Biodesign Laboratory, Harvard University (visiting graduate student)</h6>
      <h6>Stroke Biological Recovery Laboratory, Spaulding Rehabilitation Hospital (graduate research associate)</h6>
      <p>Evaluating the effects of high intensity aerobic exercise and nutrition on motor performance</p>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2017<br />-<br />2020</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Dietitian</h4>
      <h6>Brigham and Women’s Hospital</h6>
      <p>Conducted nutrition assessments, counseling and clinical care for our inpatient population. Cared for individuals managing diabetes, kidney disease, cancer, Irritable Bowel Disease and surgical recovery, among other medical conditions</p> 
      </div>
    </div>
    <div className="timeline-item">
      <h5>2011<br />-<br />2019</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Group Fitness Instructor and Personal Trainer</h4>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2012<br />-<br />2015</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Research Assistant</h4>
      <h6>Body Composition Laboratory, USDA Human Nutrition Research Center on Aging</h6>
      <p>Led and contributed to studies assessing micronutrient status in infants and adults around the world</p>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2011<br />-<br />2012</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4>Research Assistant</h4>
      <h6>Advanced Technology Laboratories, University of Connecticut</h6>
      <p>Conducted research on phytochemicals (extracted from plant foods) and their associated biological benefits</p>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2010<br />-<br />2011</h5>
      <h3>
        •
        <div className="line second-last" />
      </h3>
      <div className="timeline-info">
      <h4>Research Assistant</h4>
      <h6>Human Performance Laboratory, University of Connecticut</h6>
      <p>Collaborated on studies assessing protein supplementation and athletic performance in elite endurance athletes</p>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2008<br />-<br />2011</h5>
      <h3>
        •
        <div className="line last" />
      </h3>
      <div className="timeline-info">
      <h4>Community Nutrition Field Instructor</h4>
      <h6>University of Connecticut</h6>
      <p>Taught children in underserved urban communities about general nutrition (with a large focus on introducing children to multiple types of fruits and vegetables)</p>
      </div>
    </div>
    </div>
    </div>
  </>;
}

export default About
